import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  pwa: true,
  gtmId: 'GTM-TMT8SVDN',
  apiEndpoint: 'https://app-globaldashboard-api-test.azurewebsites.net',
  auth0: {
    domain: 'auth-testa.tnssng.com',
    clientId: '99cK6fo8IrrT23lcjAaf3yEY9iPGPWXk',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/callback`,
      audience: 'https://app-globaldashboard-api-test.azurewebsites.net',
    },
    errorPath: '/error',
    httpInterceptor: {
      allowedList: ['https://app-globaldashboard-api-test.azurewebsites.net/*'],
    },
  },
};
